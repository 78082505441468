
import { defineComponent } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import Search from "@/components/Search.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import NotificationCard from "@/components/Notification-Card.vue";
import SplitButton from "primevue/splitbutton";
import { Operation } from "@/types/state/fieldServices";

import FieldServiceOrderService from "@/services/FieldServiceOrderService";
import Utils from "@/utility/utils";
import {
  FieldServiceObject,
  FieldServiceOrder,
} from "@/types/state/fieldServices";
import MessageBox from "./MessageBox.vue";
import ClientAPI, { DEFAULT_CLIENT_SUCCESS_HANDLER } from "@/services/ClientAPI";
const fieldServiceOrderService = new FieldServiceOrderService(
  process.env.VUE_APP_ABSTRACTION_API
);

export default defineComponent({
  components: {
    TabView,
    TabPanel,
    Button,
    Search,
    NotificationCard,
    SplitButton,
    MessageBox,
  },
  computed: {
    ...mapState(["fieldServices"]),
    ...mapGetters({
      innerTabs: "fieldServices/getInnerTabs",
      getLineItemsMap: "fieldServices/getLineItemsMap",
      getActiveTab: "fieldServices/getActiveTab",
      getOrderMap: "fieldServices/getOrderMap",
      getActiveInnerTab: "fieldServices/getActiveInnerTab",
      getActiveInnerTabSuffix: "fieldServices/getActiveInnerTabSuffix",
      getActiveFieldService: "fieldServices/getActiveFieldService",
      getClient: "session/getClient",
      getFieldServices: "fieldServices/getFieldServices",
      signatureRequired: "fieldServiceControl/signatureRequired",
      getRecentlyViewedCustomers: "customerInquiry/getRecentlyViewed",
      getAllCustomers: "customer/getAllCusts",
      getCompanyCodes: "control/getCompanyCodes",
      getOperations: "fieldServiceControl/getOperations",
    }),
    customersBadgeCount() {
      if(this.getAllCustomers.cust_items?.length) {
        return this.getAllCustomers.cust_items.length.toString();
      } else {
        return ""
      }
    },
    items() {
      return [
        {
          label: "Field Services",
          to: "/fieldservices/",
        },
        {
          label: "Recently Viewed Customers",
          to: "/fieldservices/recent-customers",
        },
        {
          label: "Search Results",
          to: "/fieldservices/search-results",
          badge: this.customersBadgeCount,
        },
      ];
    },
  },
  data: () => ({
    active: 1,
    activeLineItemTab: 0,
    splitButtonItems: [],
    showConfirmDeleteTab: false,
    tabToRemove: null,
    isLoading: false,
  }),
  methods: {
    ...mapActions({
      changeTitle: "title/changeTitle",
      removeFieldServiceTab: "fieldServices/removeOpenedFieldServiceOrder",
      removeOpenedLineItem: "fieldServices/removeOpenedLineItem",
      changeActiveTab: "fieldServices/changeActiveTab",
      changeLineItemTab: "fieldServices/changeLineItemTab",
      changeActiveInnerTab: "fieldServices/changeActiveInnerTab",
      getFieldServicePDF: "fieldServices/getFieldServicePDF",
      addNewFieldService: "fieldServices/addNewFieldService",
      addNotification: "notification/add",
      getFieldServiceControl: "fieldServiceControl/getFieldServiceControl",
      setSelectedCoCode: "customerInquiry/setSelectedCoCode",
      setLastFieldServiceOrder: "fieldServices/setLastFieldServiceOrder",
      fetchControls: "control/fetchControl",
    }),
    getOperationData(type: string) {
      const data = this.getOperations.filter(
        (item: any) => item.operation === type
      );
      if (data.length > 0) {
        return data[0];
      } else {
        return { input_type: "TEXT" };
      }
    },
    selectCustomer(event: any) {
      const cust_id = event.value.cust_id;
      const newOrders = this.getFieldServices.filter(
        (fso: FieldServiceObject) =>
          fso.old_record.order_no && fso.old_record.order_no.startsWith("New")
      );
      const newOrderCount = newOrders.length + 1;
      this.addNewFieldService(`New-${newOrderCount}`);
      this.$router.push({
        path: `/fieldservices/New-${newOrderCount}`,
        query: { cust_id: cust_id },
      });
    },
    handleFieldServiceTabChange(event: any) {
      this.active = event.index;
    },
    printPDF() {
      const id = this.getActiveFieldService.record.order_no;
      const reportType = this.getActiveFieldService.record.order_type;
      this.getFieldServicePDF({
        client: this.getClient,
        recordId: id,
        reportType: reportType,
      });
    },
    printWebPage() {
      this.$nextTick(() => {
        window.print();
      });
    },
    async saveFieldService() {
      if (this.getActiveFieldService.record.order_no.startsWith("New")) {
        if (
          this.signatureRequired &&
          !this.getActiveFieldService.record.signature
        ) {
          this.addNotification({
            type: "error",
            message: "Signature is required.",
          });
          return;
        }
        if (
          !this.getActiveFieldService.record.order_type 
        ) {
          this.addNotification({
            type: "error",
            message: "Report Type is required.",
          });
          return;
        }
        if (
          !(this.getActiveFieldService.record.oper_li_items && this.getActiveFieldService.record.oper_li_items.length)
        ) {
          this.addNotification({
            type: "error",
            message: `Press "Add Operations by Type" to add operations`,
          });
          return;
        }
        if (this.getActiveFieldService.record.oper_li_items.some((item: Operation) => !item.oper_notes && this.getOperationData(item.operation).oper_required === 'Y')) {
        this.addNotification({
          type: "error",
          message: "Operation Type is required.",
        });
        return;
        }
        this.isLoading = true;
        const oldId = this.getActiveFieldService.record.order_no;
        this.getActiveFieldService.record.order_no = null;
        if (!this.getActiveFieldService.record.end_time) {
          this.getActiveFieldService.record.end_time = Utils.formatTime(
            new Date()
          );
        }

        await fieldServiceOrderService
          .createFieldService(this.getActiveFieldService.record, DEFAULT_CLIENT_SUCCESS_HANDLER, () => ClientAPI.displayErrorNotification("Error while creating field service order."))
          .then((res: any) => {
            this.getActiveFieldService.record.order_no = res.recordId;
            this.getActiveFieldService.old_record = JSON.parse(
              JSON.stringify(this.getActiveFieldService.record)
            );
            this.setLastFieldServiceOrder(this.getActiveFieldService).then(
              () => {
                this.isLoading = false;
                this.$router.push(`/fieldservices/created`);
              }
            );
          })
          .catch((err) => {
            // If there is any error while creating the field service order, then revert the order number to the old one
            this.getActiveFieldService.record.order_no = oldId;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = true;

        const id = this.getActiveFieldService.record.order_no;
        const oldRecord = this.getActiveFieldService.old_record;
        const newRecord = this.getActiveFieldService.record;
        await fieldServiceOrderService
          .updateFieldService(id, oldRecord, newRecord, this.getClient)
          .then((res: any) => {
            this.addNotification({
              type: "success",
              message: `Field Service Order #${res.recordId} updated successfully.`,
            });
            // Return record is returning the old object. Retrieving the updated object from the API for now.
            fieldServiceOrderService
              .getFieldServiceById(this.getClient, id, "", "")
              .then((res: any) => {
                if (res.fso_items[0]) {
                  const fso = res.fso_items[0];
                  this.getActiveFieldService.old_record = JSON.parse(
                    JSON.stringify(fso)
                  );
                  this.getActiveFieldService.record = JSON.parse(
                    JSON.stringify(fso)
                  );
                }
                this.setLastFieldServiceOrder(this.getActiveFieldService).then(
                  () => {
                    this.isLoading = false;
                    this.$router.push(`/fieldservices/created`);
                  }
                );
              })
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    changeToolBarTitle(sideMenuLabel: any) {
      this.changeTitle(sideMenuLabel.label);
    },
    handleTabChange(event: any) {
      this.changeActiveTab(event.index !== undefined ? event.index : 0);
      if (event.index > 0) {
        const id =
          this.fieldServices.FieldServiceMap[event.index - 1].old_record
            .order_no;
        this.$router.push(`/fieldservices/${id}`);
      } else {
        this.$router.push("/fieldservices");
      }
    },
    handleCloseSaveFieldServiceTab() {
      this.active = 2;
      if (this.tabToRemove) {
        this.removeFieldServiceTab(this.tabToRemove);
      }
      this.$router.push("/fieldservices");
      this.showConfirmDeleteTab = false;
    },
    handleCancelSaveFieldServiceTab() {
      this.showConfirmDeleteTab = false;
    },
    handleRemoveFieldServiceTab(tab: any) {
      const hasChanges = Utils.compareTwoObjects<FieldServiceOrder>(
        tab.record,
        tab.old_record,
        ["web_group"]
      );

      if (hasChanges) {
        this.tabToRemove = tab;
        this.showConfirmDeleteTab = true;
        return;
      }
      this.active = 2;
      this.removeFieldServiceTab(tab);
      this.$router.push("/fieldservices");
    },
    handleCustomerSearch(data: any) {
      this.$router.push({
        path: "/fieldservices/search-results",
      });
    },
    handleInnerTabChange(event: any, tab: any) {
      this.changeActiveInnerTab({
        orderId: tab.old_record.order_no,
        tabIndex: event.index,
      });
    },
    handleCreatedOrUpdated(hookName?: string) {
      const activeOrderTab = this.getActiveTab;
      const orders = this.getOrderMap;
      const orderId =
        orders.length > 0 && orders[activeOrderTab - 1]
          ? orders[activeOrderTab - 1].old_record.order_no
          : "";
      this.activeLineItemTab = 0;
      if (
        activeOrderTab > 0 &&
        orders.length > 0 &&
        this.activeLineItemTab > 0
      ) {
        const itemId =
          this.getLineItemsMap(orderId)[this.activeLineItemTab - 1].lis;
        this.$router.push(`/fieldservices/${orderId}/line-item/${itemId}`);
      } else if (
        hookName == "created" &&
        activeOrderTab > 0 &&
        orders.length > 0 &&
        this.activeLineItemTab === 0
      ) {
        this.$router.push(`/fieldservices/${orderId}`);
      }
      if (this.activeLineItemTab === 0 && this.getActiveInnerTab(orderId) > 0) {
        const suffix = this.getActiveInnerTabSuffix(orderId);
        this.$router.push(`/fieldservices/${orderId}/${suffix}`);
      }
    },
    orderHasChanges(fieldService: any) {
      const hasChanges = Utils.compareTwoObjects<FieldServiceOrder>(
        fieldService.record,
        fieldService.old_record,
        ["web_group"]
      );
      return hasChanges ? "*" : "";
    },
  },
  created() {
    if (this.getCompanyCodes == null || this.getCompanyCodes.length === 0) {
      this.fetchControls({
        id: "COMPANY",
        procedure: "CO.CONTROL",
        filename: "CONTROL",
      }).then(() => {
        this.setSelectedCoCode(
          this.getCompanyCodes && this.getCompanyCodes.length > 0
            ? this.getCompanyCodes[0].code
            : ""
        );
      });
    } else {
      this.setSelectedCoCode(
        this.getCompanyCodes && this.getCompanyCodes.length > 0
          ? this.getCompanyCodes[0].code
          : ""
      );
    }
    this.getFieldServiceControl();
    this.handleCreatedOrUpdated("created");
    this.splitButtonItems = [
      {
        label: "Print PDF",
        icon: "pi pi-print",
        command: () => {
          this.printPDF();
        },
      },
    ] as any;
  },
  updated() {
    this.handleCreatedOrUpdated();
  },
});
